@mixin setProperty($property, $darkValue, $lightValue) {
  #{$property}: #{$darkValue};
  @media (prefers-color-scheme: dark) {
    #{$property}: #{$darkValue};
  }

  @at-root [data-theme='light'] & {
    #{$property}: #{$darkValue};
  }
  @at-root [data-theme='dark'] & {
    #{$property}: #{$darkValue};
  }
}
